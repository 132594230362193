<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          @click="edit"
          color="primary"
          class="my-prof-controls-flex"
          fab
          x-large
          icon
          dark
        >
          <v-icon center>mdi-account-edit-outline</v-icon>
          <span class="d-flex" style="font-size:11px;margin-top:5px">
            Ред. профиль
          </span>
        </v-btn>
      </template>
      <span>Редактировать профиль</span>
    </v-tooltip>
    <v-dialog
      v-model="editUserInfoDialog"
      scrollable
      fullscreen
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-mob-p-d"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title>
                    Редактирование профиля
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn @click="closeDialog()" icon absolute right>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-title
                  class="headline"
                  style="font-size: 1rem !important"
                >
                  Введите подробную информацию о себе:
                </v-card-title>
                <v-form ref="form" lazy-validation>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <v-text-field
                          ref="firstnameInput"
                          v-model="firstname"
                          :counter="20"
                          :rules="nameRules"
                          @keyup.enter="onFirstNameChange"
                          label="Имя"
                          filled
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <v-textarea
                          ref="hdLevelSelect"
                          v-model="hd_level"
                          :value="hd_level"
                          :counter="256"
                          :rules="hdLevelRules"
                          name="hdlevel"
                          filled
                          label="Уровень познаний в HD"
                          rows="1"
                          auto-grow
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <!-- <div
                          class="headline"
                          style="font-size: 1rem !important"
                        >
                          Введите дату рождения:
                        </div> -->
                        <div
                          v-if="age === '0'"
                          class="headline"
                          style="font-size: 1rem !important"
                        >
                          Введите дату рождения:
                        </div>
                        <div
                          v-else
                          class="headline"
                          style="font-size: 1rem !important"
                        >
                          Дата и город рождения уже сохранены
                        </div>
                        <!-- <div
                          v-else
                          class="headline"
                          style="font-size: 1rem !important"
                        >
                          Менять дату и место рождения возможно только, имея
                          премиум аккаунт
                        </div> -->
                      </v-col>
                      <!-- <v-col cols="4" sm="2" md="2" lg="2" xl="2"> -->
                      <v-col cols="4" sm="2" md="2" lg="2" xl="2">
                        <v-select
                          ref="daySelect"
                          v-on:change="onDayChange"
                          v-model="day"
                          :hint="`день`"
                          :items="days"
                          :rules="dayRules"
                          item-text="day"
                          label="Выберите день"
                          filled
                          persistent-hint
                          return-object
                          single-line
                          required
                          menu-props="auto, bottom, offset-y"
                        ></v-select>
                      </v-col>
                      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                        <v-select
                          ref="monthSelect"
                          v-on:change="onMonthChange"
                          v-model="month"
                          :hint="`месяц`"
                          :items="months"
                          :rules="monthRules"
                          item-text="month"
                          label="Выберите месяц"
                          filled
                          persistent-hint
                          return-object
                          single-line
                          required
                          menu-props="auto, bottom, offset-y"
                        ></v-select>
                      </v-col>
                      <v-col cols="4" sm="2" md="2" lg="2" xl="2">
                        <v-select
                          ref="yearSelect"
                          v-on:change="onYearChange"
                          v-model="year"
                          :hint="`год`"
                          :items="years"
                          :rules="yearRules"
                          item-text="year"
                          label="Выберите год"
                          filled
                          persistent-hint
                          return-object
                          single-line
                          required
                          menu-props="auto, bottom, offset-y"
                          open-on-clear
                        ></v-select>
                      </v-col>
                      <v-col cols="6" sm="2" md="2" lg="2" xl="2">
                        <v-select
                          ref="hourSelect"
                          v-on:change="onHourChange"
                          v-model="hour"
                          :hint="`часы`"
                          :items="hours"
                          :rules="hourRules"
                          item-text="hour"
                          label="Выберите час"
                          filled
                          persistent-hint
                          return-object
                          single-line
                          required
                          menu-props="auto, bottom, offset-y"
                        ></v-select>
                      </v-col>
                      <v-col cols="6" sm="2" md="2" lg="2" xl="2">
                        <v-select
                          ref="minuteSelect"
                          v-on:change="onMinuteChange"
                          v-model="minute"
                          :hint="`минуты`"
                          :items="minutes"
                          :rules="minuteRules"
                          item-text="minute"
                          label="Выберите минуту"
                          filled
                          persistent-hint
                          return-object
                          single-line
                          required
                          menu-props="auto, bottom, offset-y"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <v-autocomplete
                          ref="cityInput"
                          v-model="city"
                          @change="onCityChange"
                          :items="cities"
                          :loading="isLoading"
                          :search-input.sync="search"
                          :no-filter="true"
                          :rules="cityRules"
                          chips
                          clearable
                          hide-details
                          hide-selected
                          item-text="title"
                          item-value="id"
                          label="Город (место) рождения"
                          name="someusercity"
                          filled
                          required
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-title v-if="!search || isLoading">
                                Ближайший крупный <strong>город</strong>...
                              </v-list-item-title>
                              <v-list-item-title
                                v-else
                                class="v-list-item-title-custom"
                              >
                                <span
                                  v-if="!searchResult"
                                  v-html="getMessage('tooltip_find_city')"
                                ></span>
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                          <template
                            v-slot:selection="{ attr, on, item, selected }"
                          >
                            <v-chip
                              v-bind="attr"
                              v-on="on"
                              :input-value="selected"
                              color="white"
                              class="black--text"
                            >
                              <span v-text="item.title"></span>&nbsp;
                              <img
                                v-if="item.country"
                                :src="
                                  'https://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                                    item.country.iso +
                                    '.svg'
                                "
                                height="15"
                              />
                            </v-chip>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item.title"
                              ></v-list-item-title>
                              <v-list-item-subtitle
                                v-if="item.country"
                                v-text="item.country.title"
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <img
                                :src="
                                  'https://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                                    item.country.iso +
                                    '.svg'
                                "
                                height="15"
                              />
                            </v-list-item-action>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <v-autocomplete
                          ref="city2Input"
                          v-model="city2"
                          @change="onCity2Change"
                          :items="cities2"
                          :loading="isLoading2"
                          :search-input.sync="search2"
                          :no-filter="true"
                          :rules="cityRules"
                          chips
                          clearable
                          hide-details
                          hide-selected
                          item-text="title"
                          item-value="id"
                          label="Город (текущее местоположение)"
                          name="someusercity"
                          filled
                          required
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-title v-if="!search2 || isLoading2">
                                Ближайший крупный <strong>город</strong>...
                              </v-list-item-title>
                              <v-list-item-title
                                v-else
                                class="v-list-item-title-custom"
                              >
                                <span
                                  v-if="!searchResult"
                                  v-html="getMessage('tooltip_find_city')"
                                ></span>
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                          <template
                            v-slot:selection="{ attr, on, item, selected }"
                          >
                            <v-chip
                              v-bind="attr"
                              v-on="on"
                              :input-value="selected"
                              color="white"
                              class="black--text"
                            >
                              <span v-text="item.title"></span>&nbsp;
                              <img
                                v-if="item.country"
                                :src="
                                  'https://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                                    item.country.iso +
                                    '.svg'
                                "
                                height="15"
                              />
                            </v-chip>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item.title"
                              ></v-list-item-title>
                              <v-list-item-subtitle
                                v-if="item.country"
                                v-text="item.country.title"
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <img
                                v-if="item.country"
                                :src="
                                  'https://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                                    item.country.iso +
                                    '.svg'
                                "
                                height="15"
                              />
                            </v-list-item-action>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-textarea
                          ref="sloganInput"
                          v-model="slogan"
                          :value="slogan"
                          :counter="256"
                          :rules="sloganRules"
                          name="slogan"
                          filled
                          label="Настроение сегодня"
                          rows="1"
                          auto-grow
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          ref="hdTargetSelect"
                          v-model="targets"
                          @change="onHdTargetsChange"
                          :hint="`Цель знакомства (необязательно)`"
                          :items="targetSelect"
                          :value="targetSelect.value"
                          label="Цель знакомства"
                          chips
                          multiple
                          filled
                          persistent-hint
                          return-object
                          single-line
                          menu-props="auto, bottom, offset-y"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-textarea
                          ref="aboutInput"
                          v-model="about"
                          :value="about"
                          :counter="256"
                          :rules="aboutRules"
                          name="about"
                          filled
                          label="О себе"
                          rows="1"
                          auto-grow
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-textarea
                          ref="whyamihereInput"
                          v-model="why_am_i_here"
                          :value="why_am_i_here"
                          :counter="256"
                          :rules="whyamihereRules"
                          name="why_am_i_here"
                          filled
                          label="Зачем я здесь?"
                          rows="1"
                          auto-grow
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <v-select
                          ref="hdHeightSelect"
                          v-model="height"
                          :hint="`Рост (необязательно)`"
                          :items="heights"
                          label="Рост"
                          filled
                          persistent-hint
                          return-object
                          single-line
                          menu-props="auto, bottom, offset-y"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <v-select
                          ref="hdWeightSelect"
                          v-model="weight"
                          :hint="`Вес (необязательно)`"
                          :items="weights"
                          label="Вес"
                          filled
                          persistent-hint
                          return-object
                          single-line
                          menu-props="auto, bottom, offset-y"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          ref="hdsexSelect"
                          v-model="sex"
                          :hint="`Пол`"
                          :items="sexselect"
                          label="Пол"
                          filled
                          persistent-hint
                          return-object
                          single-line
                          menu-props="auto, bottom, offset-y"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          ref="hdmaritalStatusSelect"
                          v-model="maritalStatus"
                          :hint="`Семейное положение (необязательно)`"
                          :items="maritalStatuses"
                          label="Семейное положение"
                          filled
                          persistent-hint
                          return-object
                          single-line
                          menu-props="auto, bottom, offset-y"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          ref="hdchildrenSelect"
                          v-model="children"
                          @change="onHdChildrenChange"
                          :hint="`Дети (необязательно)`"
                          :items="childrenSelect"
                          :value="childrenSelect.value"
                          label="Дети"
                          chips
                          multiple
                          filled
                          persistent-hint
                          return-object
                          single-line
                          menu-props="auto, bottom, offset-y"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          ref="hdVisitedСountriesSelect"
                          v-model="visitedСountries"
                          :value="visitedСountries"
                          :counter="256"
                          :rules="visitedСountriesRules"
                          name="visitedСountries"
                          filled
                          label="Я был(а) в этих странах (списком через запятую)"
                          rows="1"
                          auto-grow
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          ref="hdIUnderstand"
                          v-model="iUnderstand"
                          :value="iUnderstand"
                          :counter="256"
                          :rules="iUnderstandRules"
                          name="iUnderstand"
                          filled
                          label="Я разбираюсь (необязательно)"
                          rows="1"
                          auto-grow
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                @click="validateSaveProfile"
                color="primary darken-1"
                class="small-med"
                >Сохранить</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
        <div class="swipe-button2">
          <v-icon class="box bounce-3" x-large
            >mdi-gesture-swipe-vertical</v-icon
          >
        </div>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="successSaveProfile" top color="success">
      <v-icon color="white">mdi-check-circle-outline</v-icon>
      &nbsp;Ваш профиль успешно сохранен.
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="successSaveProfile = false"
          color="red"
          x-small
          text
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="unsuccessSaveProfile" top color="warning">
      <v-icon color="white">mdi-alert</v-icon>
      &nbsp;Не все поля заполненны.
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="unsuccessSaveProfile = false"
          color="red"
          x-small
          text
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";

export default {
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  mixins: [commonInitAppMethods],
  data() {
    return {
      editUserInfoDialog: false,
      successSaveProfile: false,
      unsuccessSaveProfile: false,
      isLoading: false,
      isLoading2: false,
      images: [],
      imagesSources: [],
      profileImages: [],
      imagesLimit: 9,
      isImageValidation: true,
      search: null,
      search2: null,
      searchResult: true,
      imagesIds: "",
      hdTargetsSelected: [],
      hdChildrenSelected: [],
      weights: [
        "не указан",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100",
        "более 100кг"
      ],
      heights: [
        "не указан",
        "150",
        "151",
        "152",
        "153",
        "154",
        "155",
        "156",
        "157",
        "158",
        "159",
        "160",
        "161",
        "162",
        "163",
        "164",
        "165",
        "166",
        "167",
        "168",
        "169",
        "170",
        "171",
        "172",
        "173",
        "174",
        "175",
        "176",
        "177",
        "178",
        "179",
        "180",
        "181",
        "182",
        "183",
        "184",
        "185",
        "186",
        "187",
        "188",
        "189",
        "190",
        "191",
        "192",
        "193",
        "194",
        "195",
        "196",
        "197",
        "198",
        "199",
        "200",
        "более 2м"
      ],
      nameRules: [
        (v) => !!v || "Введите имя.",
        (v) => (v && v.length <= 20) || "Имя должно быть не более 20 символов.",
        (v) => (v && !v.includes(" ")) || "Имя не должно содержать пробелов."
      ],
      dayRules: [(v) => !!v || "Выберите день."],
      monthRules: [(v) => !!v || "Выберите месяц."],
      yearRules: [(v) => !!v || "Выберите год."],
      hourRules: [(v) => !!v || "Выберите час."],
      minuteRules: [(v) => !!v || "Выберите минуту."],
      cityRules: [(v) => !!v || "Выберите город."],
      hdLevelRules: [
        (v) => !!v || "Укажите ваш уровень познаний в HD",
        (v) =>
          (v && v.length <= 256) ||
          "Описание должно быть не более 256 символов."
      ],
      sloganRules: [
        (v) => !!v || "Опишите ваше настроение. Какие желания на сегодня?",
        (v) =>
          (v && v.length <= 256) ||
          "Описание должно быть не более 256 символов.",
        (v) =>
          (v && !v.includes("@") && !v.includes("#")) ||
          "Контактные данные и символы «@, #» запрещены."
      ],
      aboutRules: [
        (v) => !!v || "Напишите о себе.",
        (v) =>
          (v && v.length >= 10 && v.length <= 256) ||
          "Описание должно быть не менее 10 и более 256 символов.",
        (v) =>
          (v && !v.includes("@") && !v.includes("#")) ||
          "Контактные данные и символы «@, #» запрещены."
      ],
      whyamihereRules: [
        (v) => !!v || "Опишите вашу цель.",
        (v) =>
          (v && v.length >= 10 && v.length <= 256) ||
          "Описание должно быть не менее 10 и более 256 символов.",
        (v) =>
          (v && !v.includes("@") && !v.includes("#")) ||
          "Контактные данные и символы «@, #» запрещены."
      ],
      visitedСountriesRules: [
        (v) =>
          (!v.includes("@") && !v.includes("#")) ||
          "Контактные данные и символы «@, #» запрещены."
      ],
      iUnderstandRules: [
        (v) =>
          (!v.includes("@") && !v.includes("#")) ||
          "Контактные данные и символы «@, #» запрещены."
      ],
      cities: [],
      cities2: []
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      HttpOptions: (state) => state.conf.http_options,
      ConfApp: (state) => state.conf.ConfApp,
      dateTime: (state) => state.account.dateTime
    }),
    days() {
      return this.dateTime.days;
    },
    months() {
      return this.dateTime.months;
    },
    years() {
      return this.cutAllYearsMoreCurrent(this.dateTime.years);
    },
    hours() {
      return this.dateTime.hours;
    },
    minutes() {
      return this.dateTime.minutes;
    },
    imageErrors() {
      const errors = [];
      const tempErrs = [];
      this.images.forEach((item) => {
        if (item.size > 15728640) tempErrs.push(item.name);
      });
      if (tempErrs.length && this.images.length > this.howManyImgsCanUpload) {
        errors.push(
          "Файлы: " +
            tempErrs.join(", ") +
            ` — больше 15Mb, вы можете загрузить не более ${this.howManyImgsCanUpload} файлов.`
        );
        this.$store.commit("account/update_comm_images_validation", false);
      } else if (tempErrs.length) {
        errors.push("Файлы: " + tempErrs.join(", ") + " — больше 15Mb.");
        this.$store.commit("account/update_comm_images_validation", false);
      } else if (this.images.length > this.howManyImgsCanUpload) {
        errors.push(
          `Вы можете загрузить не более ${this.howManyImgsCanUpload} файлов.`
        );
        this.$store.commit("account/update_comm_images_validation", false);
      } else {
        this.$store.commit("account/update_comm_images_validation", true);
      }
      return errors;
    },
    firstname: {
      get() {
        return this.User.community.my_profile.name;
      },
      set(value) {
        this.$store.commit("UPDATE_MY_NAME", value);
      }
    },
    day: {
      get() {
        return this.User.community.my_profile.day;
      },
      set(value) {
        this.$store.commit("account/update_comm_my_day", value);
      }
    },
    month: {
      get() {
        return this.User.community.my_profile.month;
      },
      set(value) {
        this.$store.commit("account/update_comm_my_month", value);
      }
    },
    year: {
      get() {
        return this.User.community.my_profile.year;
      },
      set(value) {
        this.$store.commit("account/update_comm_my_year", value);
      }
    },
    hour: {
      get() {
        return this.User.community.my_profile.hour;
      },
      set(value) {
        this.$store.commit("account/update_comm_my_hour", value);
      }
    },
    minute: {
      get() {
        return this.User.community.my_profile.minute;
      },
      set(value) {
        this.$store.commit("account/update_comm_my_minute", value);
      }
    },
    city: {
      get() {
        return this.User.community.my_profile.cityid;
      },
      set(value) {
        this.$store.commit("account/update_comm_my_city_id", value);
        this.mathCityName(value);
      }
    },
    citiesFromStore: {
      get() {
        return this.User.community.my_profile.cities;
      },
      set(value) {
        this.$store.commit("account/update_comm_my_last_cities", value);
      }
    },
    city2: {
      get() {
        return this.User.community.my_profile.city2id;
      },
      set(value) {
        this.$store.commit("account/update_comm_my_city2_id", value);
        this.mathCity2Name(value);
      }
    },
    cities2FromStore: {
      get() {
        return this.User.community.my_profile.cities2;
      },
      set(value) {
        this.$store.commit("account/update_comm_my_last_cities2", value);
      }
    },
    cityname: {
      get() {
        return this.User.community.my_profile.city;
      }
    },
    age: {
      get() {
        return this.User.community.my_profile.age;
      },
      set(value) {
        this.$store.commit("account/update_user_my_age", value);
      }
    },
    targets: {
      get() {
        return this.User.community.my_profile.targets;
      },
      set(value) {
        this.$store.commit("account/set_user_my_targets", value);
      }
    },
    targetSelect: {
      get() {
        return this.User.community.target_select;
      },
      set(value) {
        this.$store.commit("SET_USER_TARGET_SELECT", value);
      }
    },
    isImagesValidation: {
      get() {
        return this.User.community.isImagesValidation;
      },
      set(value) {
        this.$store.commit("account/update_comm_images_validation", value);
      }
    },
    birthday() {
      return (
        this.year.year +
        "-" +
        this.month.id +
        "-" +
        this.day.day +
        " " +
        this.hour.hour +
        ":" +
        this.minute.minute +
        ":00"
      );
      /* if (this.age === '0') {
        return (
          this.year.year +
          '-' +
          this.month.id +
          '-' +
          this.day.day +
          ' ' +
          this.hour.hour +
          ':' +
          this.minute.minute +
          ':00'
        )
      } else {
        return ''
      } */
    },
    slogan: {
      get() {
        return this.User.community.my_profile.slogan;
      },
      set(value) {
        this.$store.commit("account/update_comm_my_slogan", value);
      }
    },
    about: {
      get() {
        return this.User.community.my_profile.about;
      },
      set(value) {
        this.$store.commit("account/update_comm_my_about", value);
      }
    },
    why_am_i_here: {
      get() {
        return this.User.community.my_profile.why_am_i_here;
      },
      set(value) {
        this.$store.commit("account/update_comm_my_why_am_i_here", value);
      }
    },
    hd_level: {
      get() {
        return this.User.community.my_profile.hd_level;
      },
      set(v) {
        this.$store.commit("account/update_comm_my_hd_level", v);
      }
    },
    howManyImgsCanUpload() {
      if (
        this.profileImages.length &&
        this.profileImages.length <= this.imagesLimit
      ) {
        return this.imagesLimit - this.profileImages.length;
      } else {
        return this.imagesLimit;
      }
    },
    height: {
      get() {
        return this.User.community.my_profile.height;
      },
      set(value) {
        this.$store.commit("account/set_comm_my_height", value);
      }
    },
    weight: {
      get() {
        return this.User.community.my_profile.weight;
      },
      set(value) {
        this.$store.commit("account/set_comm_my_weight", value);
      }
    },
    sex: {
      get() {
        return this.User.community.my_profile.sex;
      },
      set(obj) {
        this.$store.commit("account/set_user_my_sex", obj);
      }
    },
    sexselect: {
      get() {
        return this.User.community.my_profile.sexselect;
      },
      set(value) {
        this.$store.commit("account/set_user_my_sexselect", value);
      }
    },
    maritalStatus: {
      get() {
        return this.User.community.my_profile.marital_status;
      },
      set(value) {
        this.$store.commit("account/set_user_my_marital_status", value);
      }
    },
    maritalStatuses: {
      get() {
        return this.User.community.marital_statuses;
      },
      set(value) {
        this.$store.commit("SET_USER_MARITAL_STATUSES", value);
      }
    },
    children: {
      get() {
        return this.User.community.my_profile.children;
      },
      set(value) {
        this.$store.commit("account/set_user_my_children", value);
      }
    },
    childrenSelect: {
      get() {
        return this.User.community.children_select;
      },
      set(value) {
        this.$store.commit("SET_USER_CHILDREN_SELECT", value);
      }
    },
    visitedСountries: {
      get() {
        return this.User.community.my_profile.visited_countries;
      },
      set(value) {
        this.$store.commit("account/set_user_my_visited_countries", value);
      }
    },
    iUnderstand: {
      get() {
        return this.User.community.my_profile.i_understand;
      },
      set(value) {
        this.$store.commit("account/set_user_i_understand", value);
      }
    }
  },
  watch: {
    search(val) {
      if (!val) return;
      this.fetchCitiesDebounced(val);
    },
    search2(val) {
      if (!val) return;
      this.fetchCitiesDebounced(val, true);
    }
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "account/call_dialog_edit_profile":
          this.editUserInfoDialog = true;
          break;
      }
    });
    this.cities = this.citiesFromStore;
    this.cities2 = this.cities2FromStore;
    setTimeout(() => {
      if (this.User.community.edit_social_profile)
        this.editUserInfoDialog = true;
    }, 0);
  },
  methods: {
    ...mapMutations({
      update_comm_city_name: "account/update_comm_city_name",
      update_comm_last_cities: "account/update_comm_last_cities",
      update_comm_last_cities2: "account/update_comm_last_cities",
      update_login_dialog: "conf/update_login_dialog",
      update_comm_my_city2_name: "account/update_comm_my_city2_name",
      update_before_editing_profile_dialog:
        "account/update_before_editing_profile_dialog",
      call_dialog_edit_profile: "account/call_dialog_edit_profile",
      call_dialog_edit_profile_off: "account/call_dialog_edit_profile_off"
    }),
    edit() {
      if (this.User.isLogged && this.User.community.beforeEditingProfile) {
        this.update_before_editing_profile_dialog(true);
      } else if (this.User.isLogged) {
        this.editUserInfoDialog = true;
      } else {
        this.update_login_dialog(true);
      }
    },
    mathCityName(id) {
      for (const item of this.cities) {
        if (item.id === id) {
          this.update_comm_city_name(item.title);
          break;
        }
      }
    },
    mathCity2Name(id) {
      for (const item of this.cities2) {
        if (item.id === id) {
          this.update_comm_my_city2_name(item.title);
          break;
        }
      }
    },
    validateSaveProfile() {
      // console.log(this.$refs.form.validate(), this.isImagesValidation)
      if (this.$refs.form.validate() && this.isImagesValidation) {
        this.saveProfile();
      } else {
        this.unsuccessSaveProfile = true;
      }
    },
    onHdTargetsChange() {
      this.hdTargetsSelected = [];
      this.targets.forEach((item) => {
        this.hdTargetsSelected.push(+item.value);
      });
    },
    onHdChildrenChange() {
      this.hdChildrenSelected = [];
      this.children.forEach((item) => {
        this.hdChildrenSelected.push(+item.value);
      });
    },
    onFirstNameChange() {
      this.$refs.hdLevelSelect.activateMenu();
      this.$refs.hdLevelSelect.focus();
    },
    onHdLevelChange() {
      if (this.age === "0") {
        this.$refs.daySelect.activateMenu();
        this.$refs.daySelect.focus();
      } else {
        this.$refs.city2Input.focus();
      }
    },
    onDayChange() {
      this.$refs.monthSelect.activateMenu();
      this.$refs.monthSelect.focus();
    },
    onMonthChange() {
      this.$refs.yearSelect.activateMenu();
      this.$refs.yearSelect.focus();
    },
    onYearChange() {
      this.$refs.hourSelect.activateMenu();
      this.$refs.hourSelect.focus();
    },
    onHourChange() {
      this.$refs.minuteSelect.activateMenu();
      this.$refs.minuteSelect.focus();
    },
    onMinuteChange() {
      this.$refs.cityInput.focus();
    },
    onCityChange() {
      this.$refs.cityInput.blur();
      this.$refs.city2Input.focus();
    },
    onCity2Change() {
      this.$refs.city2Input.blur();
      this.$refs.sloganInput.focus();
    },
    fetchCitiesDebounced(val, secondCity) {
      let request = val.trim();
      request = request.replace(/(\s*)(-)(\s*)/, "$2"); // eslint-disable-line
      request = request.replace(/\s+/, " "); // eslint-disable-line
      if (secondCity) {
        this.search2 = request;
        this.isLoading2 = true;
      } else {
        this.search = request;
        this.isLoading = true;
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        if (secondCity) {
          this.fetchCities(this.search2, secondCity);
          this.isLoading2 = false;
        } else {
          // this.fetchCities(this.search, secondCity)
          this.fetchCities(this.search);
          this.isLoading = false;
        }
      }, 1000); /* 1000ms throttle */
    },
    async fetchCities(searchrequest, secondCity) {
      if (searchrequest) {
        try {
          const result = await Api.searchCity(searchrequest, this.HttpOptions);
          if (result.success) {
            if (secondCity) {
              this.cities2 = result.cities;
              this.update_comm_last_cities2(result.cities);
              if (!this.cities2.length) {
                this.searchResult = false;
              }
            } else {
              this.cities = result.cities;
              this.update_comm_last_cities(result.cities);
              if (!this.cities.length) {
                this.searchResult = false;
              }
            }
          }
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      }
    },
    sortArr(arr) {
      return arr.slice().sort((a, b) => {
        return a.id - b.id;
      });
    },
    imageDel(e, i) {
      const el =
        e.target.parentElement.parentElement.parentElement.parentElement;
      el.style.cssText = "transform: scale(0); transition: transform 0.2s";
      clearTimeout(this.imagesIds);
      this.imagesIds = setTimeout(() => {
        this.images.splice(i, 1);
        this.imagesSources.forEach((item, j) => {
          if (j === i) this.imagesSources.splice(j, 1);
        });
        el.style = "";
      }, 200);
    },
    async imageDelUploaded(e, i, id) {
      try {
        const data = {
          "access-token": this.User.access_token,
          image_id: id
        };
        const result = await this.$removeAvatarSocial.post(data);
        if (result.success) {
          const el =
            e.target.parentElement.parentElement.parentElement.parentElement;
          el.style.cssText = "transform: scale(0); transition: transform 0.2s";
          setTimeout(() => {
            this.profileImages.splice(i, 1);
            el.style = "";
          }, 200);
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    async imageSortUploaded() {
      try {
        const sortedObject = {};
        this.profileImages.forEach((item, i) => {
          sortedObject[item.id] = i;
        });
        const data = { data: sortedObject };
        await this.$sortAvatarSocial.post(data);
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    async saveProfile() {
      this.editUserInfoDialog = false;
      this.call_dialog_edit_profile_off(false);
      if (this.User.isLogged) {
        try {
          // console.log(this.avatar)
          const formData = new FormData();
          this.images.forEach((file, i) => {
            formData.append(`files[${i}]`, file);
          });
          // formData.append('access-token', this.User.access_token)
          // formData.append('partner_id', this.ConfApp.partner_id)
          formData.append("first_name", this.firstname);
          formData.append("city_of_birth_id", this.city);
          formData.append("city_id", this.city2);
          formData.append("birthday", this.birthday);
          formData.append("hd_level", this.hd_level);
          if (!this.hdTargetsSelected.length) this.onHdTargetsChange();
          // console.log('hdTargetsSelected: ', this.hdTargetsSelected)
          formData.append("targets", JSON.stringify(this.hdTargetsSelected));
          formData.append("slogan", this.slogan);
          formData.append("about", this.about);
          formData.append("why_am_i_here", this.why_am_i_here);
          if (this.height !== "не указан") {
            formData.append("height", this.height);
          } else {
            formData.append("height", 0);
          }
          if (this.weight !== "не указан") {
            formData.append("weight", this.weight);
          } else {
            formData.append("weight", 0);
          }
          // console.log(this.sex)
          if (this.sex) formData.append("sex", this.sex.value);
          if (this.maritalStatus)
            formData.append("marital_status", this.maritalStatus.value);
          if (!this.hdChildrenSelected.length) this.onHdChildrenChange();
          // console.log('hdChildrenSelected: ', this.hdChildrenSelected)
          formData.append("children", JSON.stringify(this.hdChildrenSelected));
          formData.append("visited_countries", this.visitedСountries);
          formData.append("i_understand", this.iUnderstand);
          const result = await Api.updateSocialProfile(
            formData,
            this.HttpOptions
          );
          // console.log('result: ', result)
          if (result.social_profile.age >= 0) {
            this.$store.commit(
              "account/update_user_my_age",
              result.social_profile.age.toString()
            );
          }
          if (result.social_profile.images) {
            this.images = [];
            this.profileImages = [];
            result.social_profile.images.forEach((item) => {
              this.profileImages.push({
                id: item.id,
                name: item.src.toString().match(/.*\/(.+?)\.*$/)[1],
                src: item.src
              });
            });
          }
          if (result.success) {
            this.successSaveProfile = true;
            this.$store.commit("conf/update_service_modal", {
              message: this.getMessage("social_text_post_editing_profile")
            });
            this.$store.commit("account/call_update_profile");
          }
          if (result.social_profile.can_add_content_to_social_network) {
            this.$store.commit(
              "account/can_add_content_to_social_network",
              result.social_profile.can_add_content_to_social_network
            );
          }
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      }
    },
    closeDialog() {
      this.editUserInfoDialog = false;
      this.call_dialog_edit_profile_off(false);
    }
  }
};
</script>
<style scoped>
.swipe-button2 {
  display: flex;
  position: sticky;
  z-index: 101;
  height: 100px;
  width: 100%;
  bottom: 155px;
  justify-content: center;
  padding-right: 10px;
  opacity: 0.7;
}
.box {
  align-self: flex-end;
  animation-name: bounce-3, fade;
  animation-duration: 5s, 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: 3, 1;
  animation-delay: 0s, 11s;
  height: 40px;
  transform-origin: bottom;
  width: 40px;
}
.bounce-3 {
  animation-timing-function: ease;
}
@keyframes bounce-3 {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-25px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
