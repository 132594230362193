<template>
  <v-dialog
    :value="showDialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    @input="closeDialog()"
    content-class="log-reg-dialog"
    scrollable
    max-width="980px"
    style="z-index: 9999"
  >
    <v-card
      :style="{
        'background-image': 'url(' + imgReg + ') !important',
        'background-size': 'cover',
        'background-position': 'center'
      }"
    >
      <v-card-title>
        <span class="headline" style="font-size: 1.3rem !important"
          >&nbsp;</span
        >
        <v-btn
          @click="closeDialog()"
          class="bg-color-reset"
          icon
          absolute
          right
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="no-top-bottom-padding margin-top-70">
        <v-container
          class="no-top-bottom-padding layout column justify-center align-center"
        >
          <v-tabs
            v-if="renderLogin"
            v-model="activetab"
            :class="{ 'height-1': User.isLogged }"
            class="v-tabs-login-reg"
            background-color="transparent"
            color="basil"
            grow
          >
            <v-tab v-if="!User.isLogged">
              Регистрация
            </v-tab>
            <v-tab v-else class="hide">&nbsp;</v-tab>
            <v-tab v-if="!User.isLogged">Вход</v-tab>
            <v-tabs-items v-model="activetab">
              <v-tab-item>
                <Registration
                  :closeButton.sync="closeButtonAttr"
                  :email.sync="email"
                  :activeNameTab.sync="activeNameTab"
                  @close="closeDialog"
                />
              </v-tab-item>
              <v-tab-item>
                <Login
                  :closeButton.sync="closeButtonAttr"
                  :email.sync="email"
                  :activeNameTab.sync="activeNameTab"
                  :routePath.sync="routePath"
                  @close="closeDialog"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import Registration from "@/components/forms/Registration.vue";
import Login from "@/components/forms/Login.vue";

export default {
  name: "LoginDiaolog",
  components: {
    Registration,
    Login
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    routePath: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      knowledge: 75,
      show: false,
      renderLogin: true,
      closeButtonAttr: false
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      HttpOptions: (state) => state.conf.http_options,
      ConfApp: (state) => state.conf.ConfApp
    }),
    activeNameTab: {
      get() {
        return this.User.activeNameTab;
      },
      set(value) {
        this.$store.commit("SET_ACTIVE_NAME_TAB", value);
      }
    },
    email: {
      get() {
        return this.User.email;
      },
      set(value) {
        this.$store.commit("account/update_email", value);
      }
    },
    activetab: {
      get() {
        const tabNum = this.$store.state.conf.RegTab
          ? this.$store.state.conf.RegTab
          : 0;
        return tabNum;
      },
      set(value) {
        this.$store.commit("conf/update_reg_tab", value);
      }
    },
    firstname: {
      get() {
        return this.User.name;
      },
      set(value) {
        this.$store.commit("account/update_name", value);
      }
    },
    imgReg() {
      if (this.$vuetify.theme.dark) {
        return "https://static.human-design.space/widget/images/bgs/reg-info-bg.png";
      } else {
        return "https://static.human-design.space/widget/images/bgs/reg-info-bg-light.png";
      }
    }
  },
  methods: {
    closeDialog() {
      this.$store.commit("forms/setRegSuccess", "");
      this.$store.commit("forms/setRegErrors", "");
      this.$store.commit("forms/setLogSuccess", "");
      this.$store.commit("forms/setLogErrors", "");
      this.$emit("close", false);
    }
  }
};
</script>
