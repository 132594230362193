<template>
  <v-dialog
    :value="showDialog"
    @input="closeDialog()"
    content-class="showDialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    scrollable
    max-width="950px"
    style="z-index: 9999"
  >
    <v-card>
      <v-card-title>
        <span
          v-html="matchTitle"
          class="headline"
          style="font-size: 1.3rem !important"
        ></span>
        <v-btn @click="closeDialog()" icon absolute right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-tabs
        v-model="tab"
        class="custom-user-tabs"
        centered
        background-color="transparent"
        color="basil"
        grow
        show-arrows
        stacked
      >
        <v-tab v-for="(item, idx) in arr" :key="idx" exact>
          {{ item.id }}
        </v-tab>
      </v-tabs>
      <v-window v-model="tab">
        <v-window-item v-for="(item, idx) in arr" :key="idx">
          <v-card scrollable>
            <v-card-text v-if="item.tooltip">
              <span
                v-html="item.title"
                class="headline"
                style="font-size: 1.3rem !important"
              ></span>
              <div v-html="item.tooltip"></div>
            </v-card-text>
            <v-card-text v-if="item.description">
              <span
                v-html="item.title"
                class="headline"
                style="font-size: 1.3rem !important"
              ></span>
              <div v-html="item.description"></div>
            </v-card-text>
          </v-card>
        </v-window-item>
      </v-window>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "MultiTooltipDiaolog",
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    arr: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      tab: this.arr.findIndex((i) => i.id === this.arr[0].id),
      show: false
    };
  },
  computed: {
    firstname: {
      get() {
        return this.$store.state.account.User.name;
      },
      set(value) {
        this.$store.commit("account/update_first_name", value);
      }
    },
    matchTitle() {
      return "";
      /* return this.obj.icon
        ? `<span style="color:${
            this.$store.state.account.User.gexagrammColor[this.obj.gate]
          }
          ">${this.obj.icon}</span> ${this.obj.title}`
        : this.obj.title; */
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    }
  }
};
</script>
<style scoped>
.custom-user-tabs.v-tabs {
  flex: initial;
}
</style>
