<template>
  <div>
    <span class="display-0-20" style="color:#00bfa5">
      <v-icon color="teal accent-4">mdi-handshake</v-icon> Партнерская программа
    </span>
    <span class="display-0-24">
      <v-icon
        @click="partnerDialog('partners')"
        style="font-size:14px;color:#fb8c01"
      >
        mdi-help-circle-outline
      </v-icon>
    </span>
    <v-dialog
      v-model="partnerInfoDialog"
      scrollable
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="partnerInfoDialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-bottom-padding">
          <v-container class="no-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-bottom-padding display-0-24-mob-p-d"
              >
                <span
                  v-html="partnerInfoText"
                  style="display:block;margin-bottom:33px;font-size:16px;line-height:20px"
                >
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                @click="partnerInfoDialog = false"
                to="/profile"
                color="primary darken-1"
                class="small-med"
                >Подробнее</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";

export default {
  mixins: [commonInitAppMethods],
  data() {
    return {
      partnerInfoDialog: false,
      partnerInfoText: null
    };
  },
  methods: {
    partnerDialog(key) {
      this.partnerInfoText = this.getMessage(key);
      this.partnerInfoDialog = true;
    }
  }
};
</script>
