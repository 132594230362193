<template>
  <v-card flat d-flex pa-2 class="card-md-small login-reg-tabs-bg">
    <v-card-title
      class="headline padding-bottom-0 headline-login-reg-height"
      style="font-size: 1rem !important"
    >
      Введите свой Email и пароль
    </v-card-title>
    <v-form ref="form2" @keyup.native.enter="validateLog" lazy-validation>
      <v-card-text class="padding-top-0 padding-bottom-0">
        <v-row>
          <v-col cols="12" class="text-center">
            <div class="log-sucsess">{{ logSuccess }}</div>
            <div class="log-errors">{{ logErrors }}</div>
          </v-col>
          <v-col
            class="padding-top-0 padding-bottom-0"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <v-text-field
              v-model="emailmd"
              :rules="[rules.required, rules.emailLogin]"
              validate-on-blur
              label="E-mail"
              required
            ></v-text-field>
          </v-col>
          <v-col
            class="padding-top-0 padding-bottom-0"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <v-text-field
              @click:append="show = !show"
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show ? 'text' : 'password'"
              validate-on-blur
              label="Пароль"
              hint="Пароль имеет допустимое значение"
              counter
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="text-right nopadding">
            <v-btn @click="toReset" class="forget-password" small text>
              Забыли пароль?
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="d-flex mob-button-login-dialog justify-center">
          <v-col cols="12" class="text-center margin-top-action-btn">
            <v-btn
              :loading="loading2"
              :disabled="loading2"
              @click="validateLog"
              rounded
              large
              color="primary"
              class="v-btn-custom hd-gradient min-width-170"
            >
              Войти&nbsp;&nbsp;<v-icon>mdi-account</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
// import Api from "@/api/api";
import { mapState } from "vuex";
import { commonErrorsMethods } from "@/mixins/common-errors-methods";

export default {
  name: "Login",
  mixins: [commonErrorsMethods],
  props: {
    closeButton: {
      type: Boolean,
      default: true
    },
    email: {
      type: String,
      default: ""
    },
    activeNameTab: {
      type: String,
      default: ""
    },
    routePath: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    show: false,
    loader: null,
    loading: false,
    loading2: false,
    rules: {
      required: (value) => !!value || "Обязательный параметр.",
      min: (v) => {
        v = v.trim();
        // this.password = v;
        if (v) {
          const s =
            v.length >= 6 ? true : "Минимальное количество символов — 6";
          return s;
        } else {
          return false;
        }
      },
      min2: (v) => {
        v = v.trim();
        this.firstname = v;
        if (v) {
          const s =
            v.length >= 3 ? true : "Минимальное количество символов — 3";
          return s;
        } else {
          return false;
        }
      },
      counter: (v) => {
        if (v) {
          const s =
            v.length <= 35 ? true : "Максимальное количество символов — 35";
          return s;
        } else {
          return false;
        }
      },
      emailMatch: () => "Неверный email или пароль",
      emailLogin: (value) => {
        if (value) value = value.trim();
        // this.email = value;
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Неверный e-mail.";
      }
    },
    renderLogin: true,
    emailmd: "",
    password: "111111"
  }),
  computed: {
    ...mapState({ User: (state) => state.account.User }),
    activetab: {
      get() {
        const tabNum = this.$store.state.conf.RegTab
          ? this.$store.state.conf.RegTab
          : 0;
        return tabNum;
      },
      set(value) {
        this.$store.commit("conf/update_reg_tab", value);
      }
    },
    firstname: {
      get() {
        return this.User.name;
      },
      set(value) {
        this.$store.commit("account/update_first_name", value);
      }
    },
    logSuccess: {
      get() {
        return this.$store.state.forms.LoginForm.logSuccess;
      },
      set(v) {
        this.$store.commit("forms/setLogSuccess", v);
      }
    },
    logErrors: {
      get() {
        return this.$store.state.forms.LoginForm.logErrors;
      },
      set(v) {
        this.$store.commit("forms/setLogErrors", v);
      }
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "forms/closeDialog") {
        this.closeDialog();
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 5000);
      this.loader = null;
    }
  },
  beforeMount() {
    this.emailmd = this.email;
  },
  methods: {
    // async validateLog() {
    validateLog() {
      if (this.$refs.form2.validate()) {
        const data = {
          email: this.emailmd,
          password: this.password,
          path: this.routePath
        };
        this.$store.dispatch("account/getLogin", data);
      }
    },
    closeDialog() {
      this.logSuccess = "";
      this.logErrors = "";
      this.$emit("close");
    },
    toReset() {
      this.closeDialog();
      if (this.$route.path !== "/reset") this.$router.push("/reset");
    }
  }
};
</script>
