<template>
  <v-dialog
    v-model="showDialog"
    @click:outside="closeDialog"
    scrollable
    max-width="900px"
    style="z-index: 9999"
  >
    <v-card class="modal-pay-report">
      <v-card-title>
        <span class="headline" style="font-size: 1.3rem !important"
          >&nbsp;</span
        >
        <v-btn @click="closeDialog" icon absolute right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="no-top-bottom-padding">
        <v-container class="no-top-bottom-padding">
          <v-row>
            <v-col
              cols="12"
              class="text-center no-bottom-padding display-0-24-no-line-h"
            >
              <span
                v-html="message"
                style="display:block;font-size:16px;margin-bottom:33px"
              >
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="text-center padding-bottom-52"
          >
            <v-btn
              @click="closeDialog"
              color="primary darken-1"
              class="small-med"
              >Прекрасно</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ServiceCloseDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // show: false
    };
  },
  computed: {
    message() {
      return this.$store.state.conf.serviceDialog.message;
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeServiceCloseDialog");
    }
  }
};
</script>
