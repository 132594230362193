<template>
  <div style="display:flex;height:30px">
    <v-switch
      v-if="Profile.full_tooltips_count > 0"
      v-model="isFullTooltipsUsageEnabled"
      @change="changeTooltipsUsage(isFullTooltipsUsageEnabled)"
      :input-value="isFullTooltipsUsageEnabled"
      color="primary"
      style="width:50px;float:flex;margin-top:0"
      inset
    ></v-switch>
    <span
      v-if="isHelpText"
      style="font-size: 0.9rem;color:#00BA89;line-height:inherit;padding-top:5px"
    >
      Использовать подсказки
    </span>
    <v-snackbar
      v-model="swichToFullTooltipsUsage"
      :class="swichToFullTooltipsUsageColor"
      top
      style="margin-top:60px"
    >
      <div class="d-flex">
        <v-icon color="white">mdi-alert-circle-outline</v-icon>
        <div style="padding-left:7px">{{ swichToFullTooltipsUsageText }}</div>
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="swichToFullTooltipsUsage = false"
          color="red"
          x-small
          text
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Api from "@/api/api";
import { mapState } from "vuex";
import { commonErrorsMethods } from "@/mixins/common-errors-methods";

export default {
  name: "FullTooltipsSwitcher",
  props: {
    isHelpText: {
      type: Boolean,
      default: false
    }
  },
  mixins: [commonErrorsMethods],
  data() {
    return {
      swichToFullTooltipsUsage: false,
      swichToFullTooltipsUsageText: "",
      swichToFullTooltipsUsageColor: "switch-on"
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      Profile: (state) => state.account.Profile,
      HttpOptions: (state) => state.conf.http_options
    }),
    isFullTooltipsUsageEnabled: {
      get() {
        return this.Profile.is_full_tooltips_usage_enabled;
      },
      set(bul) {
        this.$store.commit("account/update_is_full_tooltips_usage_enable", bul);
      }
    }
  },
  methods: {
    async changeTooltipsUsage(v) {
      try {
        const data = { is_full_tooltips_usage_enabled: v };
        const result = await Api.setNotifications(data, this.HttpOptions);
        if (result.success) {
          // console.log(result)
          this.swichToFullTooltipsUsage = true;
          if (result.is_full_tooltips_usage_enabled) {
            this.swichToFullTooltipsUsageText =
              "Вы включили расширенные подсказки.";
            this.swichToFullTooltipsUsageColor = "switch-on";
          } else {
            this.swichToFullTooltipsUsageText =
              "Вы отключили расширенные подсказки. Теперь для новых расчетов не будут списываться балы.";
            this.swichToFullTooltipsUsageColor = "switch-off";
          }
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    }
  }
};
</script>
